body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: "SF-Mono", Menlo, Monaco, Consolas, "Courier New", monospace;
}

.chakra-menu__menu-button[data-active] svg,
.chakra-menu__menu-button:active svg {
	transform: rotate(180deg);
	transition: 0.2s ease-out;
}
